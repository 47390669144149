.layout {
  padding: 2% 1.5% 0 1.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  min-height: 100vh;
  overflow: hidden;

  .user-info {
    align-self: flex-end;
    font-size: 0.8rem;
    font-family: "GunarBold";

    &__logout-btn {
      color: $lightGrey;
      font-style: italic;
      font-family: "GunarBold";
      padding: 0.6rem 0.8rem 0.3rem 0.8rem;
      margin-left: 0.5rem;
    }
  }

  &__content {
    background: $white;
    color: $black;
    width: 90%;
    max-width: 50rem;
    margin: 13% 0 2% 0;
    padding: 1.5rem 2.5%;

    @media screen and (max-width: 1246px) {
      margin-top: 20%;
    }
    @media screen and (max-width: 840px) {
      margin-top: 30%;
    }
    @media screen and (max-width: 560px) {
      margin-top: 40%;
    }

    .event-name {
      text-align: center;
      font-family: "GunarBold";
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }

    .heading-border {
      width: 100%;
      height: 0.3rem;
      background: $lightGrey;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .dark-line {
        background: $grey;
        width: 20%;
        height: 100%;
      }
    }
  }
}
